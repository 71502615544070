<template>
  <div>
    <app-bar ref="refAppBar" @reload="reload"></app-bar>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      テンプレートのフォーマットを切り替えた際、切り替え前と後で同じ項目（タイトル、内容、画像等）がある場合は入力内容が保持されます。
    </div>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <v-icon class="pa-1" @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.image="{ item }">
          <div class="pa-1">
            <v-img
              width="50"
              height="50"
              :src="setting.cdn + item.image"
              v-if="item.image"
            ></v-img>
            <!-- <v-img
              width="50"
              height="50"
              v-else
              src="@/assets/images/no_image.jpg"
            ></v-img> -->
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.copy="{ item }">
          <v-icon class="pa-1" @click="copyItem(item)">
            mdi-content-copy
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="{ item }">
          <v-icon class="pa-1" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilTemplate from "@/model/templates";
import utilFunc from "@/common/utilFunc.js";
import appBar from "./appBar.vue";
import router from "@/router/index";

export default defineComponent({
  components: {
    appBar,
  },
  setup(_, ctx) {
    const templatesRepository = repositoryFactory.get("templates");
    const refAppBar = ref();
    const state = reactive({
      search: "",
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        { text: "画像", value: "image", sortable: false, width: 66 },
        {
          text: "テンプレート名",
          value: "name",
          sortable: true,
          class: "td_template_name",
        },
        {
          text: "フォーマット",
          value: "content_format_name",
          sortable: true,
          class: "td_content_format_name",
        },
        {
          text: "複製",
          value: "copy",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    const editItem = (template) => {
      // ダイアログ開く
      refAppBar.value.action({
        action: "edit",
        value: template.id,
        // contentFormats: {
        //   "id": template.content_format_id,
        //   "name": template.content_format_name,
        //   "value": "template",
        // },
      });
    };

    const copyItem = async (template) => {
      if (window.confirm("テンプレートを複製してよろしいですか？")) {
        // コピー処理
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        const params = {
          id: template.id,
        };
        await templatesRepository
          .copy(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
              const config = JSON.parse(response.data.config);
              store.dispatch("templates/addTemplate", {
                id: response.data.id,
                name: response.data.name,
                image: config["image_0"],
                content_format_id: response.data.content_format_id,
                content_format_name: template.content_format_name,
              });
            }
          })
          .catch((error) => {
            throw (
              "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
              error +
              ")"
            );
          });
      }

      // 表にセット(storeとバインド)
      state.desserts = store.getters["templates/getTemplateList"];

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // データ削除
    const deleteItem = async (item) => {
      if (
        window.confirm(
          "削除してよろしいですか？\n※このテンプレートを使用した更新コンテンツの設定も削除されます。"
        )
      ) {
        // 削除処理
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        // 更新
        const index = state.desserts.indexOf(item);
        await utilTemplate.deleteStore(state.desserts[index].id);
      }
    };

    // 初期化処理
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;

      // templateデータ取得
      await utilTemplate.setStore();
      // 表にセット(storeとバインド)
      state.desserts = store.getters["templates/getTemplateList"];

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    // リロード
    const reload = () => {
      init();
    };

    // 返却オブジェクト定義
    return {
      refAppBar,
      setting,
      ...toRefs(state),
      editItem,
      copyItem,
      deleteItem,
      init,
      reload,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_template_name {
  min-width: 260px;
}
::v-deep .td_content_format_name {
  min-width: 160px;
  width: 260px;
  font-size: 0.75em;
}
</style>
