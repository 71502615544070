<template>
  <!-- テンプレート登録ダイアログ -->
  <div>
    <ValidationObserver ref="observer">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
            <h3 id="body_head">当日スケジュール投稿のコメント</h3>
            <div class="mt-2">
              スケジュール予定を投稿する際のコメントを設定します。<br />
              <span :class="tweetCount > 100 ? 'text-red' : ''"
                >先頭コメント＋末尾コメント: {{ tweetCount }} /
                100文字まで可</span
              >
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="先頭コメント"
                    :rules="{
                      x_post_length: { length: 100, cnt: tweetCount },
                    }"
                  >
                    <v-textarea
                      v-model="body"
                      name="body"
                      :error-messages="errors"
                      :success="valid"
                      label="先頭コメント"
                      rows="4"
                      :placeholder="`本日出勤のキャストを紹介します。\nhttps://example.com/schedule`"
                      outlined
                      background-color="white"
                      hint="半角文字:0.5文字、全角文字:1文字、改行:0.5文字、URL:11.5文字として扱います。"
                      persistent-hint
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
            <!-- 表示する出勤内容 -->
            <div class="mt-2">
              先頭コメントと末尾コメントの間に、出勤キャストの名前と出勤時間を表示します。
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="schedule_type"
                    name="schedule_type"
                    :items="scheduleTypeItems"
                    no-data-text="表示タイプ"
                    label="表示タイプ"
                    success
                    outlined
                    hint="表示タイプの指定[必須項目]"
                    persistent-hint
                    :rules="require_rule"
                    class="mt-2"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <div class="mt-2">
              出勤キャストの下に表示する末尾コメントを設定します。
            </div>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="末尾コメント"
                    :rules="{ x_post_length: { length: 100, cnt: tweetCount } }"
                  >
                    <v-textarea
                      v-model="end_body"
                      name="end_body"
                      :error-messages="errors"
                      :success="valid"
                      label="末尾コメント"
                      rows="4"
                      :placeholder="``"
                      outlined
                      background-color="white"
                      hint="半角文字:0.5文字、全角文字:1文字、改行:0.5文字、URL:11.5文字として扱います。"
                      persistent-hint
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="6">
            <h3>投稿イメージ</h3>
            <div class="mt-2">
              Xに投稿した時のイメージです。※退勤したキャストおよび投稿文字数がオーバーする際は、キャストを省略する場合があります。
            </div>
            <div
              class="schedule-type-sample"
              v-html="
                `${
                  body ? body.replace(/\n/g, '<br />') + '<br /><br />' : ''
                }` +
                scheduleTypeSample[schedule_type] +
                `${
                  end_body
                    ? '<br /><br />' + end_body.replace(/\n/g, '<br />')
                    : ''
                }`
              "
            ></div>
          </v-col>
        </v-row>
      </v-container>

      <!-- 画像選択コンポーネント -->
      <imageComponent
        ref="refImageComponent"
        :template_config="template_config"
      ></imageComponent>
      <h3>1回のポストで掲載する画像枚数</h3>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-select
              v-model="photo_count"
              name="photo_nums"
              :items="photoNumItems"
              no-data-text="画像枚数"
              label="画像枚数"
              success
              outlined
              hint="画像枚数の指定[必須項目]"
              persistent-hint
              :rules="require_rule"
              class="mt-2"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
  watch,
} from "@vue/composition-api";
import setting from "@/common/setting.js";
import imageComponent from "./itemComponent/image";
import utilDate from "@/common/utilDate.js";
import utilTime from "@/common/utilTime.js";
import twitterText from "twitter-text";

export default defineComponent({
  components: {
    imageComponent,
  },
  props: ["template_config"],
  setup(props, ctx) {
    const state = reactive({
      photo_count: 1,
      schedule_type: 1,
      title: "",
      body: "",
      end_body: "",
      tweetCount: 0,
    });

    const photoNumItems = [
      { value: 1, text: "1枚" },
      { value: 2, text: "2枚" },
      { value: 3, text: "3枚" },
      { value: 4, text: "4枚" },
    ];
    const scheduleTypeItems = [
      { value: 1, text: "キャスト名と開始〜終了時刻を表示" },
      { value: 2, text: "キャスト名と開始時刻を表示" },
      { value: 3, text: "キャスト名のみを表示" },
    ];
    const scheduleTypeSample = {
      1: `10:00-15:00 Ａ子<br />12:00-18:00 Ｂ子<br />21:00-01:00 Ｃ子<br />00:00-05:00 Ｄ子`,
      2: `10:00- Ａ子<br />12:00- Ｂ子<br />21:00- Ｃ子<br />00:00- Ｄ子`,
      3: `Ａ子<br />Ｂ子<br />Ｃ子<br />Ｄ子`,
    };

    const clearDialog = async () => {
      state.photo_count = 1;
      state.schedule_type = 1;
      state.title = "";
      state.body = "";
      state.end_body = "";
    };

    const setData = async () => {
      // 各項目に値をセット
      const config = props.template_config;
      state.photo_count = config.photo_count ?? 1;
      state.schedule_type = config.schedule_type ?? 1;
      state.title = config.title_1000;
      state.body = config.body_10000;
      state.end_body = config.end_body;
    };

    onMounted(async () => {
      // 初期化
      await clearDialog();
      // データセット
      if (props.template_config) {
        await setData(props.template_config);
      }
    });

    // 保存前のチェック処理
    const checkValid = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
        const el = document.querySelectorAll(
          ".v-text-field.error--text, .v-textarea.error--text"
        );
        if (el.length > 0) {
          el[0].scrollIntoView();
        }
      }

      return isValid;
    };

    // 保存処理
    const onSubmit = async () => {
      // 保存処理
      const config = {};
      config["photo_count"] = state.photo_count;
      config["schedule_type"] = state.schedule_type;
      config["title_1000"] = state.title;
      config["body_10000"] = state.body;
      config["end_body"] = state.end_body;

      // 画像コンポーネントの設定を取得
      const imageConfig = await ctx.refs.refImageComponent.saveConfig();

      return JSON.stringify({ ...config, ...imageConfig });
    };

    // 値が変わった時に実行する処理
    const onBodyChange = (newValue, oldValue) => {
      const v = (newValue ?? "") + (state.end_body ?? "");
      const pt = twitterText.parseTweet(v);
      state.tweetCount = pt.weightedLength / 2;
    };
    watch(() => state.body, onBodyChange);
    const onEndBodyChange = (newValue, oldValue) => {
      const v = (state.body ?? "") + (newValue ?? "");
      const pt = twitterText.parseTweet(v);
      state.tweetCount = pt.weightedLength / 2;
    };
    watch(() => state.end_body, onEndBodyChange);

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      photoNumItems,
      scheduleTypeItems,
      scheduleTypeSample,
      checkValid,
      onSubmit,
      require_rule: [(v) => !!v || "必須選択項目です"],
    };
  },
});
</script>

<style scoped>
.editable-div {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 100px;
}

.highlight {
  background-color: yellow;
}

.schedule-type-sample-title {
  margin: 6px;
  color: black;
  font-weight: bold;
}
.schedule-type-sample {
  padding: 16px 8px;
  margin: 6px;
  border: solid 1px #333;
  border-radius: 6px;
}
</style>
